<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
        style="background-color: white"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <!-- Brand logo-->
          <div>
              <h1 class="brand-text text-primary mb-3 text-center">
                <b-img
                    :src="appLogoImage"
                    alt="logo"
                />
              </h1>
          </div>
          <!-- /Brand logo-->

          <!-- Login-->
          <div v-if="state === 0">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t('login.welcome.title') }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('login.welcome.message') }}
            </b-card-text>
            <b-alert
              :show="dismissCountDown"
              dismissible
              variant="warning"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >
              <div class="error-mesage">
                {{ errorMesage }}
              </div>
            </b-alert>
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- username -->
                <b-form-group
                  :label="$t('login.field.username')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('login.field.username')"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      name="login-username"
                      :placeholder="$t('login.placeholder.username')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t('login.field.password') }}</label>
                    <b-link @click="toResetPassword">
                      <small>{{ $t('login.forgotPassword') }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('login.field.password')"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{ $t('login.field.rememberMe') }}
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('login.button.signIn') }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <!-- /Login-->
          <!-- ResetPassword-->
          <div v-else-if="state === 1">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t('login.resetPassword.title') }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('login.resetPassword.message') }}
            </b-card-text>
            <b-card-text class="mb-2">
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="warning"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                {{ errorMesage }}
              </b-alert>
            </b-card-text>
            <!-- form -->
            <validation-observer
              ref="resetPassword"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="resetPassword"
              >
                <!-- username -->
                <b-form-group
                  :label="$t('login.field.username')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      name="login-username"
                      :placeholder="$t('login.placeholder.username')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('login.button.resetPassword') }}
                </b-button>
                <b-button
                  block
                  @click="toLogin"
                >
                  {{ $t('login.button.return') }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <!-- /ResetPassword-->
          <!-- ResetPasswordResult-->
          <div v-else-if="state === 2">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t('login.resetPasswordEmail.title') }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('login.resetPasswordEmail.message1') }}
            </b-card-text>
            <b-card-text class="mb-2">
              <h2 class="text-primary d-flex justify-content-center">
                {{ email }}
              </h2>
            </b-card-text>
            <b-card-text class="mb-2">
              {{ $t('login.resetPasswordEmail.message2') }}
            </b-card-text>
            <!-- submit buttons -->
            <b-button
              block
              @click="toLogin"
            >
              {{ $t('login.button.return') }}
            </b-button>
          </div>
          <div v-else-if="state === 3">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t('login.resetPasswordEmail.errorTitle') }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('login.resetPasswordEmail.error') }}
            </b-card-text>
            <!-- submit buttons -->
            <b-button
              block
              @click="toLogin"
            >
              {{ $t('login.button.return') }}
            </b-button>
          </div>
          <!-- /ResetPasswordResult-->
          <!-- ChangePassword-->
          <div v-else-if="state === 4">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t('login.changePassword.title') }}
            </b-card-title>
            <br>
            <change-password />
            <br>
            <b-button
              block
              @click="toLogin"
            >
              {{ $t('login.button.return') }}
            </b-button>
          </div>
          <!-- /ChangePassword-->
        </b-col>
      </b-col>
    </b-row>
    <b-modal
      ref="password-expiration-modal"
      hide-header-close
      hide-footer
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :title="$t('login.passwordExpiration.title')"
    >
      <br>
      <br>
      <div class="d-block text-center">
        <h3 class=" text-warning">
          {{ $t('login.passwordExpiration.message', { days: passwordExpireDays }) }}
        </h3>
      </div>
      <hr>
      <b-button
        class="mt-2"
        variant="primary"
        block
        @click="toChangePassword"
      >
        {{ $t('password.actions.changePassword') }}
      </b-button>
      <b-button
        class="mt-2"
        variant="outline-primary"
        block
        @click="toHome"
      >
        {{ $t('password.actions.continue') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import AuthStoreModule from './storeModule'
import ChangePassword from './password/ChangePassword.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    Logo,
    ValidationProvider,
    ValidationObserver,
    ChangePassword,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const username = localStorage.getItem('username')
    const status = localStorage.getItem('rememberMeChecked')

    return {
      status: status || '',
      password: '',
      username: username || '',
      sideImg: require('@/assets/images/logo/logo-meraki.png'),
      appLogoImage: require('@/assets/images/logo/logo-login.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      errorMesage: null,
      dismissSecs: 6,
      dismissCountDown: 0,
      state: 0,
      AUTH_STORE_MODULE_NAME: 'auth',
      passwordExpireDays: 0,
      userData: null,
      errorMessages: {
        unexpectedError: this.$t('login.error.unexpectedError'),
        expiredPassword: this.$t('login.error.expiredPassword'),
        badCredencial: this.$t('login.error.badCredencial'),
        badCredencialMaxAttempts: this.$t('login.error.badCredencialMaxAttempts'),
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule(this.AUTH_STORE_MODULE_NAME)) store.registerModule(this.AUTH_STORE_MODULE_NAME, AuthStoreModule)
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.AUTH_STORE_MODULE_NAME)) store.unregisterModule(this.AUTH_STORE_MODULE_NAME)
  },
  methods: {
    showPasswordExpirationModal() {
      console.info('show modal')
      this.$refs['password-expiration-modal'].show()
    },
    toChangePassword() {
      this.state = 4
      this.$refs['password-expiration-modal'].hide()
    },
    toLogin() {
      this.state = 0
    },
    toResetPassword() {
      this.state = 1
    },
    resetPassword() {
      const self = this
      store.dispatch('auth/getResetPassword', { username: this.username })
        .then(response => {
          self.state = 2
          self.email = response.data.email
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: self.$t('login.resetPasswordEmail.title'),
              icon: 'MailIcon',
              variant: 'success',
              text: `${self.$t('login.resetPasswordEmail.title')} ${self.email} ${self.$t('login.resetPasswordEmail.message1')}`,
            },
          })
        })
        .catch(error => {
          self.state = 3
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: self.$t('login.resetPasswordEmail.errorTitle'),
              icon: 'MailIcon',
              variant: 'danger',
              text: self.$t('login.resetPasswordEmail.error'),
            },
          })
        })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    getFullName(data) {
      const names = ['firt_name', 'middle_name', 'surname_1', 'surname_2']
      let fullName = data[names[0]]

      for (let i = 1; i < names.length; i++) {
        if (names[i] in data && data[names[i]] && data[names[i]].trim() != 'null') {
          fullName += ` ${data[names[i]].trim()}`
        }
      }
      return fullName
    },
    getRoles() {
      if (this.userData) {
        const { roles } = this.userData
        if (roles && roles.length > 0) {
          let roles_str = roles[0].name
          for (let i = 1; i < roles.length; i++) {
            roles_str += ` / ${roles[i].name}`
          }
          return roles_str
        }
      }
      return ''
    },
    toHome() {
      this.$router.replace(getHomeRouteForLoggedInUser(this.userData.role))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.$t('login.welcome.title')} ${this.userData.fullName || this.userData.username}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `${this.$t('login.welcome.logged')} ${this.getRoles()}`,
            },
          })
        })
    },
    login() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      if (this.status) {
        localStorage.setItem('username', this.username)
        localStorage.setItem('rememberMeChecked', true)
      } else {
        localStorage.removeItem('username')
        localStorage.removeItem('rememberMeChecked')
      }
      const self = this
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            username: this.username,
            password: this.password,
          })
            .then(response => {
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)

              useJwt.getUser({}).then(response => {
                this.userData = {
                  roles: response.data.roles,
                  fullName: this.getFullName(response.data),
                  username: response.data.username,
                  avatarId: response.data.avatar_id,
                  arl: response.data.arl,
                }

                localStorage.setItem('userData', JSON.stringify(this.userData))
                if (response.data.config.passwordExpireDays <= response.data.config.passwordExpireWarn) {
                  this.showPasswordExpirationModal()
                  this.passwordExpireDays = response.data.config.passwordExpireDays
                } else {
                  this.toHome()
                }
              // this.$ability.update(userData.ability)
              })
            })
            .catch(error => {
              self.$refs.loginForm.setErrors('error')
              if (error.response && error.response.status === 401) {
                console.info(error.response.data.message, 'passwordExpired')
                const key = error.response.data.message
                if (key in self.errorMessages) {
                  self.errorMesage = self.errorMessages[key]
                } else {
                  self.errorMesage = this.$t('login.error.unexpectedError')
                }
              } else if (error.response && error.response.status === 403) {
                this.errorMesage = 'Usuario inactivo. Por favor, contacte al administrador.'
              } else {
                self.errorMesage = this.$t('login.error.unexpectedError')
              }
              this.showAlert()
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.error-mesage {
  padding: 10px;
}
</style>
